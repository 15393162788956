import React from "react"
import { Title } from "../Title"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 0;
  padding: 0 3.125em;
  margin-top: 6.25em;
`
const Content = styled.div`
  margin-top: 63px;
  width: 100%;
  display: flex;
  gap: 100px;
  .content-info {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .icon-title {
      text-align: center;
      width: 100%;
      h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #004182;
      }
      .img1 {
        width: 100px;
      }
      .img2 {
        width: 150px;
      }
    }
    .text {
      text-align: center;
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column;
    .content-info {
      width: 100%;
    }
  } ;
`
const TypesTraining = ({
  virtualCapContent,
  presentialCapContent,
  evaluationContent,
}) => {
  return (
    <Container>
      <Title className="blue">Tipos de capacitación</Title>
      <Content>
        <div className="content-info">
          <div className="icon-title">
            <object
              className="img1"
              data="/images/CoorporateService/icon-1.svg"
            />
            <h2 className="blue">Virtual</h2>
          </div>
          <div className="text">{virtualCapContent}</div>
        </div>
        <div className="content-info">
          <div className="icon-title">
            <object
              className="img2"
              data="/images/CoorporateService/icon-2.svg"
            />
            <h2 className="blue">Presencial</h2>
          </div>
          <div className="text">{presentialCapContent}</div>
        </div>
      </Content>
    </Container>
  )
}

export default TypesTraining

import React from "react"
import styled from "styled-components"
import { Paragraph } from "../Paragraph"

import { Title } from "../Title"

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 0;
  padding: 0 3.125em;
  margin-top: 6.25em;

  display: flex;
  gap: 5.625em;
  .text {
    width: 60%;
  }
  .image {
    width: 40%;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    .text,
    .image {
      width: 100%;
    }
    object {
      width: 100%;
    }
  }
`
const TraingingCompanies = ({ contentTitle, content }) => {
  return (
    <Container>
      <div>
        <Title className="blue">{contentTitle}</Title>
        <Paragraph>{content}</Paragraph>
      </div>
      <div className="imagen">
        <object data="/images/Courses/img-1- 1.png" />
      </div>
    </Container>
  )
}

export default TraingingCompanies

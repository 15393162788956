import * as React from "react"
import styled from "styled-components"


const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 6em 1em;
  position: relative;
  
`

const NotFoundPage = () => (
  <Container>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  
</Container>
)

export default NotFoundPage

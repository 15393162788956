import React from "react"
import styled from "styled-components"
import { Title } from "../Title"
import { Paragraph } from "../Paragraph"

const Container = styled.div`
  width: 100%;
  max-width: 80rem;
  margin: 4.125rem auto 0;
  padding: 0 3.125em;
  margin-top: 100px;
  margin-bottom: 135px;
`
const Content = styled.div`
  margin-top: 38px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .text,
  .imagen {
    width: 50%;
  }
  .imagen {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 900px) {
    .text,
    .imagen {
      width: 100%;
    }
  }
`
const EvaluationReports = ({ evaluationContent }) => {
  return (
    <Container>
      <Title className="blue">Evaluaciones y Reportes</Title>
      <Content>
        <div className="text">
          <Paragraph>{evaluationContent}</Paragraph>
        </div>
        <div className="imagen">
          <object data="/images/CoorporateService/certification-icon-blue.svg" />
        </div>
      </Content>
    </Container>
  )
}

export default EvaluationReports

import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getServicesPage } from "../../providers"

import EvaluationReports from "../../components/CoorporateServices/EvaluationReports"
import TraingingCompanies from "../../components/CoorporateServices/TrainingCompanies"
import TypesTraining from "../../components/CoorporateServices/TypesTraining"
import { Hero4 } from "../../components/Hero4"
import { MainLayout } from "../../components/Layouts/MainLayout"
import NotFoundPage from "../404.js"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const Services = () => {
  const [servicesPage, setServicesPage] = useState({})

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const _servicesPage = await getServicesPage()
        setServicesPage(_servicesPage[0])
       
      } catch (error) {
        console.log(error)
      }
    }
    fetchServices()
  }, [])

  return (
     <MainLayout>
      
      {/*
     <Hero4
        backgroundImage={servicesPage?.background_image}
        title={servicesPage?.title}
  />*/}
      <Container>
      <NotFoundPage/>

{/*
        <TraingingCompanies
          contentTitle={servicesPage?.content_title}
          content={servicesPage?.content}
        />
        <TypesTraining
          virtualCapContent={servicesPage?.virtual_cap_content}
          presentialCapContent={servicesPage?.presential_cap_content}
        />
        <EvaluationReports
          evaluationContent={servicesPage?.evaluation_content}
  />*/}
        
      </Container>
      
    </MainLayout>

  )
}

export default Services
